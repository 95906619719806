<template>
  <v-app>
    <v-main>
      <section id="404" style="padding: 96px 0px">
        <h1 class="display-2 font-weight-black mb-4 text-center">404</h1>
        <h1 class="primary--text headline font-weight-bold mb-4 text-center">Oh!</h1>
        <h1 class="headline font-weight-bold mb-8 text-center">
          Looks like this page doesn't exist
        </h1>

        <v-img src="/logo.png" class="mx-auto my-12 mb-15" contain height="250" width="250" />

        <div class="text-center">
          <v-btn
            depressed
            x-large
            color="primary"
            class="font-weight-bold"
            @click="() => $router.back()"
          >
            Get me Out of Here
          </v-btn>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "404",
  metaInfo: { title: "404! No Page Found" },
};
</script>

<style scoped>
.v-main {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
